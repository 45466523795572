import logo from './logo.svg';
import './App.css';
import { Navigate, Route, Router, Routes } from 'react-router-dom';
import Admin from './pages/admin';
import MainPage from './pages/main';
import CommentCard from './main components/commentcard';
import ReactGA from 'react-ga4';
import Privacy from './pages/privacypolicy';

function App() {
  
  ReactGA.initialize('G-RD77PL5946');

  return (
    <div>
      <Routes>
        <Route path="/admin" element={<Admin />}/>
        <Route path="/" element={<MainPage />}/>
        <Route path="/privacy" element={<Privacy />}/>
        {/* <Route path="*" element={<Navigate to="/" replace />} />  */}
      </Routes>
    </div>
  );
}

export default App;
