import useWindowDimensions from "../tools/window_resolution";

function Uploading(props){

    const screenheight = useWindowDimensions().height;
    const screenwidth = useWindowDimensions().width;

    const uploadingStyle={
        backgroundColor:"rgba(0,0,0,.5)",
        zIndex:"4",
        top:0,
        left:0,        
        position:"fixed",
        display: props.inprogress ? "flex" : "none",  
        width: screenwidth,
        height: screenheight,
    };

    const uploadingText = {
        opacity:"100%",
        color:"white",
        top:"50%",
        width: "100%",
        margin: "auto",
        textAlign: "center",
        fontSize:"large",
        transform: "translate(0, -50%)",
        zIndex:"5",  
    };

    return(
        <>
            <div style={uploadingStyle}>
                <div style={uploadingText}>Loading...</div>
            </div>
        </>
    )
}

export default Uploading;