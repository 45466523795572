import { useState } from "react";
import * as Constants from "../config";

export default function Login(props){

    const [login, setLogin] = useState();
    const [pass, setPass] = useState();

     const loginReq = () => {
        const body = Constants.requestBody({"username":login,"password":pass});
        fetch(Constants.apiurl + Constants.loginPath, body)
        .then(response => {
            if(response.status == 401)
                {
                    throw {message:"Username or password is wrong."}
                }
            return response.json();
        })
        .then(data => {
            localStorage.setItem("token",data.token);
            props.loginCompleted();
        }
         ).catch((err) => {
            alert(err.message);
         });
    }

    const onChangeLogin = (e) => {        
        const { name, value } = e.target;
        setLogin(value);
    };

    const onChangePassword = (e) => {        
        const { name, value } = e.target;
        setPass(value);
    };

    const loginStyle = {
        backgroundColor:"white",
        display:"block",
        padding:"20px"
    }

    return(
        <>
            <div style={loginStyle}>
                <div>Login:</div>
                <input onChange={onChangeLogin} value={login}/>
                <div>Password:</div>
                <input type="password" onChange={onChangePassword} value={pass}/>
                <div>
                    <button style={{
                        width:"150px",
                        height:"40px",
                        marginTop:"10px",
                        backgroundColor:"#deefff",
                        border:"2px solid gray",
                        borderRadius:"5px"}} 
                        onClick={loginReq}>Submit</button>
                </div>
            </div>
        </>
    )
}