import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material";
import { useState } from "react";


export default function AccordionBlock(props){
    const addStyle = {

    };

    const [expanded,setExpanded] = useState(props.expanded);

    return(
        <Accordion expanded={expanded}>
            <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel1-content"
            id="panel1-header"
            onClick={()=>setExpanded(!expanded)}
            >
            {props.name}
            </AccordionSummary>
            <AccordionDetails>
                {props.content}
            </AccordionDetails>
        </Accordion>
    )
}